import { process, SortDescriptor } from "@progress/kendo-data-query";
import {
    Grid,
    GridColumn,
    GridEvent,
    GridNoRecords,
    GridRowProps,
    GridToolbar,
} from "@progress/kendo-react-grid";
import classNames from "classnames";
import * as React from "react";
import { useDebouncedCallback } from "use-debounce";
import { commonFilterOperators } from "../../../../../common/components/table/kendo/columnFilters";
import GridLoadingPanel from "../../../../../common/components/table/kendo/GridLoadingPanel";
import useInfiniteDataResult from "../../../../../common/hooks/useInfiniteDataResult";
import { useNavigation } from "../../../../../router/useNavigate";
import { Work } from "../../domain/Work";
import { useGetInfiniteWork } from "../../query/MyWorkQueries";
import { MyWorkGridClaimButton } from "./MyWorkGridClaimButton";
import "./myWorkGrid.scss";
import { ExaminationState } from "../../domain/ExaminationState";
import { usePersistedMyWorkGridState } from "../../hooks/usePersistedMyWorkGridState";
import {
    MultiSelect,
    MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { FilterDescriptor } from "@progress/kendo-react-dropdowns/dist/npm/common/filterDescriptor";
import BetweenDatesFilterCell, { betweenDates } from "./BetweenDatesFilterCell";

interface Props {
    organisationId: string;
}

// Function to add a custom filter to the grid state
function applyCustomFilter(gridState, operatorFunc, field) {
    if (!gridState?.filter?.filters) {
        return gridState;
    }

    // Update the filters with the new operator for the specified field
    const newFilters = gridState.filter.filters.map((filter) =>
        filter.field === field ? { ...filter, operator: operatorFunc } : filter,
    );

    // Return a new grid state with updated filters
    return {
        ...gridState,
        filter: {
            ...gridState.filter,
            filters: newFilters,
        },
    };
}

function applyCustomFiltersOperator(gridState) {
    if (!gridState?.filter?.filters?.length) {
        return {
            ...gridState,
            filter: null,
        };
    }

    const filtersOperatorToApply = [
        { operatorFunc: betweenDates, field: "plannedDateJs" },
    ];

    return filtersOperatorToApply.reduce(
        (updatedGridState, { operatorFunc, field }) =>
            applyCustomFilter(updatedGridState, operatorFunc, field),
        gridState,
    );
}

const scrollCondition = (event: GridEvent): boolean => {
    const e = event.nativeEvent;

    return (
        e.target.scrollTop + 10 >= e.target.scrollHeight - e.target.clientHeight
    );
};

const MyWorkGrid: React.FC<Props> = ({ organisationId }) => {
    const { navigateToOrgPath: navigateToPath } = useNavigation();
    const wrapperRef = React.createRef<HTMLElement>();
    const DATA_ITEM_KEY = "id";

    const {
        setSelectedColumns,
        gridSelectedColumns,
        gridState,
        setGridState,
        setColumnsProps,
        gridColumnProps,
    } = usePersistedMyWorkGridState();

    const {
        data: work,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
    } = useGetInfiniteWork(organisationId);

    const { flatData: flatWorkData } = useInfiniteDataResult(work);

    const isLoadingWork = isFetching || isFetchingNextPage;

    const scrollHandler = useDebouncedCallback((event: GridEvent) => {
        if (scrollCondition(event) && hasNextPage && !isLoadingWork) {
            fetchNextPage();
        }
    }, 200);

    const columnConfigurations = [
        {
            key: "structureType",
            field: "structureType",
            title: "Structure Type",
            sortable: true,
            width: 200,
            orderIndex: 1,
        },
        {
            key: "reportType",
            field: "reportType",
            title: "Report Type",
            sortable: true,
            width: 200,
            orderIndex: 2,
        },
        {
            key: "elr",
            field: "elr",
            title: "ELR",
            sortable: true,
            width: 160,
            orderIndex: 3,
        },
        {
            key: "miles",
            field: "miles",
            title: "Miles",
            sortable: "true",
            width: 180,
            orderIndex: 4,
        },
        {
            key: "yards",
            field: "yards",
            title: "Yards",
            sortable: true,
            width: 180,
            orderIndex: 5,
        },
        {
            key: "chains",
            field: "chains",
            title: "Chains",
            sortable: true,
            width: 180,
            orderIndex: 6,
        },
        {
            key: "structureNumber",
            field: "structureNumber",
            title: "Structure Number",
            sortable: true,
            width: 200,
            orderIndex: 7,
        },
        {
            key: "structureName",
            field: "structureName",
            title: "Structure Name",
            sortable: true,
            width: 200,
            orderIndex: 8,
        },
        {
            key: "plannedDateJs",
            field: "plannedDateJs",
            title: "Planned Date",
            sortable: true,
            filter: "date",
            format: "{0:dd/MM/yyyy}",
            width: 300,
            filterCell: BetweenDatesFilterCell,
            orderIndex: 9,
        },
        {
            key: "contractYear",
            field: "contractYear",
            title: "Contract Year",
            sortable: true,
            width: 200,
            orderIndex: 10,
        },
        {
            key: "status",
            field: "status",
            title: "Status",
            sortable: true,
            width: 200,
            orderIndex: 11,
        },
        {
            key: "claimExam",
            field: "claimExam",
            title: "Claim Exam",
            sortable: false,
            width: 130,
            orderIndex: 12,
        },
    ];

    const result = process(
        flatWorkData
            .map((item) => ({
                ...item,
            }))
            .slice(0),
        applyCustomFiltersOperator(gridState),
    );

    const rowRender = (
        row: React.ReactElement<HTMLTableRowElement>,
        rowProps: GridRowProps,
    ) => {
        const item = rowProps.dataItem as Work;

        const trProps = {
            ...row.props,
            className: classNames(
                {
                    "table-danger":
                        item?.plannedDateJs?.getTime() <= Date.now(),
                },
                row.props.className,
            ),
        };
        return React.cloneElement(row, { ...trProps }, row.props.children);
    };

    const rowIndex = (field: string, defaultIndex: number) => {
        return (
            gridColumnProps?.find((x) => x.field === field)?.orderIndex ??
            defaultIndex
        );
    };

    const rowWidth = (field: string, defaultWidth: number) => {
        return (
            gridColumnProps?.find((x) => x.field === field)?.width ??
            defaultWidth
        );
    };

    const isColumnVisible = (field: string) => {
        return !gridSelectedColumns || gridSelectedColumns?.length === 0
            ? true
            : gridSelectedColumns?.map((x) => x.title)?.includes(field);
    };

    const columnsFieldAndText = columnConfigurations.map((config) => ({
        title: config.title,
        field: config.field,
    }));

    const onColumnChange = (e: MultiSelectChangeEvent) => {
        setSelectedColumns(e.value);

        const updatedFilters = (
            gridState?.filter?.filters as FilterDescriptor[]
        )?.filter((filter) =>
            e.value?.map((x) => x.field)?.includes(filter?.field),
        );

        const newGridState = {
            ...gridState,
            filter: {
                ...gridState?.filter,
                filters: updatedFilters,
            },
            // If column is hidden, exclude from sorting
            sort: gridState?.sort?.filter((si) =>
                e?.value?.find((ci) => ci?.field === si?.field),
            ),
        };

        setGridState(newGridState);
    };

    const onSortChange = (sort: Array<SortDescriptor>) => {
        const newGridState = {
            ...gridState,
            sort: sort,
        };

        setGridState(newGridState);
    };

    const onNavigate = React.useCallback(
        (processId: string, id: string, status: string) => {
            if (
                !!status &&
                [
                    ExaminationState.AtEngineer.toString(),
                    ExaminationState.RejectedByNetworkRail.toString(),
                    ExaminationState.RejectedByTechAdmin.toString(),
                ].includes(status)
            ) {
                navigateToPath(`/:orgShortName/engineer-review/${id}`);
            } else {
                navigateToPath(
                    `/:orgShortName/process/${processId}/activity/${id}`,
                );
            }
        },
        [navigateToPath],
    );

    const toolbar = (
        <GridToolbar>
            <div className="toolbar-wrapper">
                <div className="flex-grow-1"></div>
                <div className="toolbar-container">
                    <MultiSelect
                        data={columnsFieldAndText}
                        autoClose={false}
                        onChange={onColumnChange}
                        textField="title"
                        dataItemKey="field"
                        value={gridSelectedColumns}
                        placeholder="Select columns"
                        tags={
                            gridSelectedColumns?.length > 0
                                ? [
                                      {
                                          text: `${gridSelectedColumns?.length} columns selected`,
                                          data: columnsFieldAndText,
                                      },
                                  ]
                                : null
                        }
                        style={{
                            width: "300px",
                            height: "40px",
                        }}
                    />
                </div>
            </div>
        </GridToolbar>
    );

    return (
        <>
            <Grid
                data={result}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
                dataItemKey={DATA_ITEM_KEY}
                onScroll={scrollHandler}
                fixedScroll={true}
                reorderable={true}
                resizable={true}
                filterable
                filterOperators={commonFilterOperators}
                sortable={{
                    allowUnsort: true,
                    mode: "multiple",
                }}
                onRowDoubleClick={(e) =>
                    onNavigate(
                        e.dataItem.processId,
                        e.dataItem.id,
                        e.dataItem.status,
                    )
                }
                className="my-work-grid-h"
                rowRender={rowRender}
                onColumnResize={(e) => {
                    setColumnsProps(e.columns);
                }}
                onColumnReorder={(e) => {
                    setColumnsProps(e.columns);
                }}
                onSortChange={(e) => {
                    onSortChange(e.sort);
                }}
                pageable={true}
                take={20}
            >
                {toolbar}
                <GridNoRecords>
                    <div style={{ width: "100%", textAlign: "left" }}>
                        {isLoadingWork && "Loading..."}
                        {!isLoadingWork && "There is no data available"}
                    </div>
                </GridNoRecords>

                {columnConfigurations.map((column) => {
                    if (column.field === "claimExam") {
                        return (
                            isColumnVisible(column.title) && (
                                <GridColumn
                                    key={column.key}
                                    title={column.title}
                                    width={rowWidth(column.field, column.width)}
                                    filterable={false}
                                    cell={(props) => {
                                        return MyWorkGridClaimButton({
                                            cellprops: props,
                                            organisationId,
                                        });
                                    }}
                                    orderIndex={rowIndex(
                                        column.field,
                                        column.orderIndex,
                                    )}
                                />
                            )
                        );
                    } else {
                        return (
                            isColumnVisible(column.title) && (
                                <GridColumn
                                    key={column.key}
                                    field={column.field}
                                    title={column.title}
                                    width={rowWidth(column.field, column.width)}
                                    {...(column.filterCell && {
                                        filterCell: column.filterCell,
                                    })}
                                    {...(column.filter && {
                                        filter: column.filter as
                                            | "boolean"
                                            | "text"
                                            | "date"
                                            | "numeric",
                                    })}
                                    {...(column.format && {
                                        format: column.format,
                                    })}
                                    orderIndex={rowIndex(
                                        column.field,
                                        column.orderIndex,
                                    )}
                                />
                            )
                        );
                    }
                })}
            </Grid>
            {isLoadingWork && <GridLoadingPanel gridRef={wrapperRef} />}
        </>
    );
};

export default MyWorkGrid;

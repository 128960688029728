import {
    Grid,
    GridColumn,
    GridExpandChangeEvent,
    GridItemChangeEvent,
    GridRowClickEvent,
} from "@progress/kendo-react-grid";
import cx from "classnames";
import { ArrayHelpers, getIn, useFormikContext } from "formik";
import * as React from "react";
import { jSDateFromIso } from "../../../../helpers/dateTimeHelpers";
import { TemplateContentFileRecord } from "../../../../modules/template/domain/types";
import { downloadFormFile } from "../../../../services/FileDownloadService";
import { TypedGridCellProps } from "../../../types/TypedGridCellProps";
import { ContainsMenuFilter } from "../../table/kendo/columnFilters";
import useGridProps from "../../table/kendo/useGridProps";
import { DynamicFormContext } from "../DynamicFormContextProvider";
import FormControlFileDetails from "./FormControlFileDetails";
import FormControlMediaTableActions from "./FormControlFileTableActions";
import FormControlFileTableCommentCell from "./FormControlFileTableCommentCell";
import {
    FormControlFileTableDataItem,
    mapToFileTableItem,
} from "./FormControlFileTableDataItem";
import FormControlFileTablePreview from "./FormControlFileTablePreview";

interface FormControlMediaTableConfig {
    field: string;
    mediaOnly: boolean;
    isReadOnly: boolean;
    commentsDisabled: boolean;
}

interface Props {
    config: FormControlMediaTableConfig;
    arrayHelpers: ArrayHelpers;
    isValid: boolean;
    readonly: boolean;
}

type FileData = {
    expanded?: boolean;
    createdOn: Date;
} & Omit<TemplateContentFileRecord, "createdOn">;

const FormControlFileTable: React.FC<Props> = ({
    config,
    arrayHelpers,
    isValid,
    readonly,
}) => {
    const { getFileUrl } = React.useContext(DynamicFormContext);
    const [editId, setEditId] = React.useState<string>(null);
    const formik = useFormikContext();
    const rowData = React.useMemo(
        () =>
            (getIn(formik.values, config.field) ||
                []) as TemplateContentFileRecord[],
        [formik.values, config.field],
    );

    function getFileData(files: TemplateContentFileRecord[]): FileData[] {
        return files.map(({ createdOn, ...other }) => ({
            readOnly: config.isReadOnly,
            expanded: false,
            createdOn: jSDateFromIso(createdOn) || null,
            ...other,
        }));
    }

    const data = React.useMemo(
        () =>
            rowData.map((row, index) => mapToFileTableItem(row, index, editId)),
        [rowData, editId],
    ) as FormControlFileTableDataItem[];

    const [filesData, setFilesData] = React.useState<FileData[]>(
        getFileData(data),
    );

    React.useEffect(() => {
        setFilesData(getFileData(data));
    }, [data]);

    const { gridProps } = useGridProps(filesData, {
        filterable: true,
        sortable: true,
        initialSort: [{ field: "createdOn", dir: "desc" }],
        pageable: false,
    });

    const PreviewCell = React.useMemo(
        // eslint-disable-next-line react/display-name
        () => (cell: TypedGridCellProps<FormControlFileTableDataItem>) =>
            (
                <td>
                    <FormControlFileTablePreview cell={cell} />
                </td>
            ),
        [],
    );

    const CommentCell = React.useMemo(
        // eslint-disable-next-line react/display-name
        () => (cell: TypedGridCellProps<FormControlFileTableDataItem>) =>
            (
                <td>
                    <FormControlFileTableCommentCell cell={cell} />
                </td>
            ),
        [],
    );

    const ActionsCell = React.useMemo(
        () =>
            // eslint-disable-next-line react/display-name
            (props: TypedGridCellProps<FormControlFileTableDataItem>) =>
                (
                    <td>
                        <FormControlMediaTableActions
                            {...props}
                            readonly={readonly}
                            onRemove={(dataItem) => {
                                arrayHelpers.remove(dataItem.originalIndex);
                            }}
                            onDownload={(dataItem) => {
                                downloadFormFile(getFileUrl(dataItem.id)).catch(
                                    () => {
                                        console.error(
                                            "failed to download file",
                                        );
                                    },
                                );
                            }}
                        />
                    </td>
                ),
        [getFileUrl, arrayHelpers, readonly],
    );

    const handleRowClick = (event: GridRowClickEvent) => {
        if (!config.isReadOnly) {
            setEditId(event.dataItem.id);
        }
    };

    const handleEdit = (event: GridItemChangeEvent) => {
        const newData = data.map((item) =>
            item.uuid === event.dataItem.uuid
                ? { ...item, [event.field]: event.value }
                : item,
        );
        formik.setFieldValue(config.field, newData);
    };

    const handleExpand = React.useCallback(
        (event: GridExpandChangeEvent) => {
            const newData = filesData.map((item: FileData) => {
                if (item.id === event.dataItem.id) {
                    item.expanded = !event.dataItem.expanded;
                }
                return item;
            });
            setFilesData(newData);
        },
        [filesData],
    );

    return (
        <Grid
            {...gridProps}
            className={cx(gridProps.className, "dynamic-form-grid", {
                "is-invalid": !isValid,
            })}
            editField="inEdit"
            onRowClick={handleRowClick}
            onItemChange={handleEdit}
            detail={FormControlFileDetails}
            expandField="expanded"
            onExpandChange={handleExpand}
        >
            {config.mediaOnly && (
                <GridColumn title="Preview" width="auto" cell={PreviewCell} />
            )}
            <GridColumn
                field="fileName"
                title="File name"
                sortable
                width="auto"
                columnMenu={ContainsMenuFilter}
                editable={false}
            />
            {!config.commentsDisabled && (
                <GridColumn
                    field="comment"
                    title="Comment"
                    width="auto"
                    cell={CommentCell}
                />
            )}
            <GridColumn filterable={false} cell={ActionsCell} width="100px" />
        </Grid>
    );
};
export default FormControlFileTable;

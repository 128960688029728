import {
    Attachments,
    ExaminationReview,
    UnexaminedPart,
    UnexaminedParts,
} from "./examinationsReview";

import { DateTime } from "luxon";
import { ExaminationDto } from "./examinationDto";

export type UnexaminedPartDto = UnexaminedPart;
export type UnexaminedPartsDto = UnexaminedParts;
export type AttachmentsDto = Attachments;

export interface ExaminationReviewDto extends ExaminationDto {
    structureReferenceNumber?: string;
    assignedExaminer?: string;
    assignedEngineer?: string;
    examinationDate?: string;
    examType?: string;
}

export interface ExaminationReviewDetailsDto extends ExaminationDto {
    structureReferenceNumber?: string;
    assignedExaminer?: string;
    assignedEngineer?: string;
    chains?: string;
    approvalActivityId?: string;
}

export const mapToExaminationReview = (
    dto: ExaminationReviewDto,
): ExaminationReview => ({
    ...dto,
    examinationDate: dto.examinationDate
        ? DateTime.fromISO(dto.examinationDate).toJSDate()
        : null,
});
